import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import styles from './forms.module.scss'

const propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  touched: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
}

const SelectInput = ({
  field,
  form: { touched, errors },
  label,
  type,
  children,
  ...rest
}) => (
  <div className="field">
    {label && (
      <label className={classNames(styles.label, 'label')}>{label}</label>
    )}
    <div className="control select w-100">
      {/* <div className=""> */}
      <select className="w-100" {...field}>
        {children}
      </select>
      {/* </div> */}
    </div>
    {touched[field.name] && errors[field.name] && (
      <p
        className={classNames('help', {
          'is-danger': errors[field.name],
        })}
      >
        {errors[field.name]}
      </p>
    )}
  </div>
)

SelectInput.propTypes = propTypes

export default SelectInput
