import React, { Fragment, useEffect, useState } from "react"
import classNames from "classnames"
import moment from "moment"

import FormInput from "./FormInput.js"
import FormSelect from "./FormSelect.js"

import calendar from "../../assets/calendar.json"
import { isFutureDate } from "./services/date.js"

/**
 ** Two select fields (MM, DD) and text field (YYYY) with label and error message.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': "birth",
 **               'label': "Birth",
 **               'isRequired': true,
 **               'onChange': Function,
 **               ...props compatible in Field Component of Formik
 **             }
 **/
const FormDate = fieldProps => {
  const { month, date } = fieldProps.values

  let months = []
  const [dates, setDates] = useState(0)

  useEffect(() => {
    calendar.map(month => {
      return months.push({
        value: month.abbreviation,
        label: month.abbreviation,
      })
    })
  })

  const getDays = stateMonth => {
    let dateValues = []

    const selectedMonth = calendar.find(
      month => month.abbreviation === stateMonth
    )

    for (let counter = 1; counter < selectedMonth.days + 1; counter++) {
      dateValues.push({
        value: counter,
        label: counter,
      })
    }

    dateValues = dateValues.filter(dateValue => {
      let selectedYear = fieldProps.values.year
      let year = selectedYear.toString()
      let dateString = `${dateValue.value} ${selectedMonth.abbreviation} ${year}`
      let parsedDay = moment(dateString).day()
      let shouldReturn = true

      if( fieldProps.isWeekDaysOnly ) shouldReturn = shouldReturn && parsedDay !== 0 && parsedDay !== 6
      if( fieldProps.isFutureDatesOnly ) shouldReturn = shouldReturn && isFutureDate({ date: dateString })

      return shouldReturn;
    })

    return dateValues
  }

  const handleChange = (values, setFieldValue) => {
    if (!!values.value) {
      setFieldValue(`${fieldProps.name}.date`, { value: "" })
      setDates(getDays(values.value))
    }
  }

  const findDates = monthInput => {
    const selectedMonth = calendar.find(
      month => month.abbreviation === monthInput
    )
    let dateValues = []
    dateValues = Array.apply(null, {
      length: selectedMonth.days,
    }).map(Number.call, Number => ({
      value: Number + 1,
      label: Number + 1,
    }))

    return dateValues
  }

  useEffect(() => {
    if (month.value) {
      const dateValues = findDates(month.value)
      setDates(dateValues)
    }
  }, [])

  return (
    <Fragment>
      <div className="columns is-mobile p-0 m-0 mt-1">
        <div className="column p-0 m-0">
          <label
            className={classNames("label has-text-weight-bold", {
              "mb-0": !!fieldProps.helper,
              "has-text-weight-normal": !fieldProps.isRequired,
            })}
          >
            {fieldProps.label}
            {fieldProps.isRequired && (
              <span className="has-text-danger"> *</span>
            )}
          </label>
          {!!fieldProps.helper && (
            <span className="help">{fieldProps.helper}</span>
          )}
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column py-0">
          <FormSelect
            name={`${fieldProps.name}.month`}
            placeholder="MMM"
            options={months}
            onChange={handleChange}
            value={month}
            isRequired={fieldProps.isRequired}
            hideOptional
          />
        </div>
        <div className="column py-0">
          <FormSelect
            name={`${fieldProps.name}.date`}
            placeholder="DD"
            options={dates}
            isDisabled={!!!fieldProps.values.month.value}
            value={date}
            isRequired={fieldProps.isRequired}
            hideOptional
          />
        </div>
        <div className="column py-0">
          <FormInput
            name={`${fieldProps.name}.year`}
            pattern="[1-2]{1}[0-9]{3}"
            placeholder="YYYY"
            maxLength={4}
            type="number"
            isRequired={fieldProps.isRequired}
            hideOptional
          />
        </div>
      </div>
    </Fragment>
  )
}

export default FormDate
