import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

// import styles from './forms.module.scss'

const propTypes = {
  field: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
}

const MinimalTextInput = ({ field, label, type, isRequired }) => (
  <>
    {label && (
      <label
        className={classNames('label', {
          'has-text-weight-bold': !!isRequired,
        })}
      >
        {label}
        {!!isRequired && <span className="has-text-danger"> *</span>}
      </label>
    )}
    <div className="control">
      <input className={'input'} type={!!type ? type : 'text'} {...field} />
    </div>
  </>
)

MinimalTextInput.propTypes = propTypes

export default MinimalTextInput
