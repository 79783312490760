import React, { Fragment } from "react"
import { Field } from "formik"
import { withTranslation, useTranslation } from "react-i18next"

import MinimalTextInput from "../minimal-text-input"
import CheckBoxSection from "../checkbox/checkbox-section"

import { handleMaxLength } from "../enrollment-form/form-utils.js"

const AuthorizedPerson = ({
  values,
  touched,
  errors,
  handleOnKeyPress,
  handleChange,
  setFieldValue,
}) => {
  const { t } = useTranslation()

  const contactPerson = "Contact this number for order and delivery updates"

  return (
    <Fragment>
      <div className="mt-1">
        <CheckBoxSection
          options={[contactPerson]}
          values={values.contactPerson}
          fieldName="contactPerson"
          setFieldValue={setFieldValue}
        />
      </div>
      {values.contactPerson.length === 0 && (
        <div>
          <Field
            name="authorizedPerson.fullName"
            component={MinimalTextInput}
            label={t("Contact Person")}
            isRequired
          />
          {touched["authorizedPerson"] && errors["authorizedPerson"] && (
            <p className="help is-danger column is-12-mobile pt-0">
              {errors["authorizedPerson"]["fullName"]}
            </p>
          )}
          <div className="control is-expanded">
            <label className="label has-text-weight-bold mt-1">
              {t("Mobile Number of Contact Person")}{" "}
              <span className="has-text-danger">*</span>
            </label>
            <Field
              name="authorizedPerson.mobileNumber"
              values={values.authorizedPerson.mobileNumber}
              className="input"
              isRequired
              onChange={handleMaxLength(handleChange, 11)}
              onKeyPress={handleOnKeyPress}
            />
            {touched["authorizedPerson"] && errors["authorizedPerson"] && (
              <p className="help is-danger column is-12-mobile pt-0">
                {errors["authorizedPerson"]["mobileNumber"]}
              </p>
            )}
          </div>
        </div>
      )}
    </Fragment>
  )
}

export default withTranslation()(AuthorizedPerson)
