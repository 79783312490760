import React, { Fragment } from 'react'
import { Field, ErrorMessage } from 'formik'
import classNames from 'classnames'

import calendar from '../../assets/calendar.json'
import SelectInput from './select-input.js'

const DateInput = ({
  label,
  helper,
  name,
  isRequired,
  values,
  handleChange,
  handleMaxLength,
}) => {
  const selectedMonth = calendar.find(month => {
    return month.abbreviation === values[`${name}Month`]
  })

  const RenderDates = () => {
    let dates = []
    if (!!selectedMonth) {
      for (var i = 1; i <= selectedMonth.days; i++) {
        dates.push(i)
      }
      const Dates = () =>
        dates.map((date, index) => <option key={index}>{date}</option>)

      return <Dates />
    } else return <option hidden>-- DD --</option>
  }
  return (
    <Fragment>
      <div className="columns is-mobile p-0 m-0">
        <div className="column p-0 m-0 mt-1">
          <span
            className={classNames('label', {
              'has-text-weight-bold': !!isRequired,
            })}
          >
            {label} {!!isRequired && <span className="has-text-danger">*</span>}
          </span>
          {!!helper && <p className="is-size-7">{helper}</p>}
        </div>
      </div>

      <div className="columns is-mobile">
        <div className="column">
          <Field
            name={`${name}Month`}
            component={SelectInput}
            value={values[`${name}Month`]}
          >
            <option hidden>MM</option>
            {calendar.map(month => {
              return <option>{month.abbreviation}</option>
            })}
          </Field>
        </div>
        <div className="column">
          <Field
            name={`${name}Date`}
            value={values[`${name}Date`]}
            component={SelectInput}
            disabled={!selectedMonth}
          >
            <option hidden>DD</option>
            <RenderDates />
          </Field>
        </div>
        <div className="column">
          <Field
            name={`${name}Year`}
            value={values[`${name}Year`]}
            type="number"
            className="input"
            placeholder="YYYY"
            isRequired
            onChange={handleMaxLength(handleChange, 4)}
          />
          <div className="has-text-danger is-size-7">
            <ErrorMessage name={`${name}Year`} />
          </div>
        </div>
      </div>
    </Fragment>
  )
}

export default DateInput
