import React from "react"
import classNames from "classnames"
import { Link, navigate } from "gatsby"
import { Formik, Form, Field, ErrorMessage } from "formik"
import { useTranslation, withTranslation } from "react-i18next"

import DateInput from "../date-input"
import TextInput from "../text-input"
import HospitalSelect from "../HospitalSelect"
import MinimalTextInput from "../minimal-text-input"
import ContentFold from "../../page-content/content-fold"
import FieldContainer from "../enrollment-form/field-container"
import StickyActionFooter from "../../page-content/sticky-action-footer"
import AuthorizedPerson from "./AuthorizedPerson"

import styles from "./checkout-form.module.scss"
import { handleMaxLength } from "../enrollment-form/form-utils.js"
import { orderValidationSchema } from "../enrollment-form/helpers"
import { useContext } from "react"
import { AppContext } from "../../../context/AppContext"
import Address from "../../forms/Address/Address"
import FormDate from "../FormDate"
import { getDateString, isFutureDate } from "../services/date"
import Message from "../../elements/Message"

const MediaStickyButtons = ({ isSubmitting }) => {
  const { t } = useTranslation()
  return (
    <StickyActionFooter>
      <div className="columns field is-grouped w-100">
        <div className="control column">
          <Link to="/cart" replace className="button w-100">
            {t("Back")}
          </Link>
        </div>
        <div className="control column">
          <button
            role="link"
            to="/payment"
            type="submit"
            className="button is-link w-100"
            disabled={isSubmitting}
          >
            {t("Proceed to Payment")}
          </button>
        </div>
      </div>
    </StickyActionFooter>
  )
}

const CheckoutForm = ({ t }) => {
  const { state, dispatch } = useContext(AppContext)
  const { cart, filesArray } = state

  const handleOnKeyPress = event => {
    if (isNaN(event.key)) event.preventDefault()
  }

  const addressFieldValues = {
    addressType: "shippingAddress.addressType",
    streetAddress: "shippingAddress.streetAddress",
    province: "shippingAddress.province",
    city: "shippingAddress.city",
    barangay: "shippingAddress.barangay",
  }

  const hospitalAddressFieldValues = {
    province: "hospital.province",
    city: "hospital.city",
  }

  return (
    <Formik
      initialValues={cart}
      validationSchema={orderValidationSchema}
      onSubmit={(values, { setSubmitting, setErrors }) => {
        let preferredDeliveryDate = values.preferredDeliveryDate
        preferredDeliveryDate = getDateString({
          dateObject: preferredDeliveryDate,
        })

        if (
          !isFutureDate({ date: preferredDeliveryDate, includeToday: true })
        ) {
          setErrors({
            preferredDeliveryDate: {
              month: { value: "Please enter a valid delivery date." },
              date: { value: "Please enter a valid delivery date." },
            },
          })
          return values
        }

        const payload = {
          ...cart,
          ...values,
          customerFirstName: values.customerFirstName,
          customerLastName: values.customerLastName,
          customerEmail: values.customerEmail,
          birthMonth: values.birthMonth,
          birthDate: values.birthDate,
          birthYear: values.birthYear,
          birthday: `${values.birthMonth || ""} ${values.birthDate ||
            ""} ${values.birthYear || ""}`,
          mobileNumber: values.mobileNumber,
          shippingAddress: {
            addressType: values.shippingAddress.addressType,
            streetAddress: values.shippingAddress.streetAddress,
            barangay: values.shippingAddress.barangay,
            province: values.shippingAddress.province,
            city: values.shippingAddress.city,
            landmarks: values.shippingAddress.landmarks,
          },
          deliveryNotes: values.deliveryNotes,
          prescribingDoctor: values.prescribingDoctor,
          hospital: {
            name: values.hospital.name,
            otherHospital: values.hospital.otherHospital,
            province: values.hospital.province,
            city: values.hospital.city,
          },
          authorizedPerson: {
            fullName: values.authorizedPerson.fullName,
            mobileNumber: values.authorizedPerson.mobileNumber,
          },
        }
        dispatch({
          type: "SET_CART_DETAILS",
          payload,
        })
        setSubmitting(false)
        navigate("/payment")
      }}
    >
      {({
        values,
        touched,
        handleChange,
        errors,
        setFieldValue,
        isSubmitting,
        submitCount,
      }) => (
        <Form
          className={classNames(styles.formBody, "form-body")}
          autoComplete="off"
        >
          <ContentFold title="Delivery Details" isCentered>
            <h4 className="has-text-weight-bold is-size-4 has-text-centered py-1 mt-2">
              {t("Personal Details")}
            </h4>
            <div className="field is-grouped columns is-mobile is-multiline">
              <span className="column is-6-mobile pb-0">
                <Field
                  name="customerFirstName"
                  component={MinimalTextInput}
                  label={t("First Name")}
                  isRequired
                />
                {touched["customerFirstName"] &&
                  errors["customerFirstName"] && (
                    <p className="help is-danger column is-12-mobile pt-0">
                      {errors["customerFirstName"]}
                    </p>
                  )}
              </span>
              <span className="column is-6-mobile pb-0">
                <Field
                  name="customerLastName"
                  component={MinimalTextInput}
                  label={t("Last Name")}
                  isRequired
                />
                {touched["customerLastName"] && errors["customerLastName"] && (
                  <p className="help is-danger column is-12-mobile pt-0">
                    {errors["customerLastName"]}
                  </p>
                )}
              </span>
            </div>
            <Field
              name="customerEmail"
              component={TextInput}
              label={t("Email")}
              type="email"
              isRequired
            />
            <label className="label has-text-weight-bold">
              {t("Mobile Number")} <span className="has-text-danger">*</span>
            </label>

            <div className="field">
              <div className="control is-expanded">
                <Field
                  name="mobileNumber"
                  value={values["mobileNumber"]}
                  className="input"
                  isRequired
                  onChange={handleMaxLength(handleChange, 11)}
                  onKeyPress={handleOnKeyPress}
                />
                {touched["mobileNumber"] && errors["mobileNumber"] && (
                  <p
                    className={classNames("help", {
                      "is-danger": errors["mobileNumber"],
                    })}
                  >
                    {errors["mobileNumber"]}
                  </p>
                )}
                <AuthorizedPerson
                  t={t}
                  values={values}
                  touched={touched}
                  errors={errors}
                  handleOnKeyPress={handleOnKeyPress}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                />
              </div>
              <DateInput
                label={t("Birthday")}
                name="birth"
                errors={errors}
                values={values}
                touched={touched}
                handleChange={handleChange}
                handleMaxLength={handleMaxLength}
                isRequired
              />
              <Field
                name="deliveryNotes"
                component={TextInput}
                label={t("Optional Notes")}
              />
              <h4 className="has-text-weight-bold has-text-centered is-size-4 py-1 mt-2">
                {t("Delivery Address")}
              </h4>
              <Address
                values={values.shippingAddress}
                isRequired
                setFieldValue={setFieldValue}
                withAddressType
                fieldNames={addressFieldValues}
                withBarangay
                isRequired
              />
              <FormDate
                name="preferredDeliveryDate"
                label="Preferred Delivery Date"
                helper="We will do our best to deliver your medicines at the preferred delivery date. Please wait for the final delivery schedule that we will send to you via SMS."
                isRequired
                values={values.preferredDeliveryDate}
                onChange={handleChange}
                isWeekDaysOnly
                isFutureDatesOnly
              />

              <h4 className="has-text-weight-bold has-text-centered is-size-4 py-1 mt-2">
                {t("Hospital/Clinic Details")}
              </h4>
              <FieldContainer label="Prescribing Doctor" isRequired>
                <Field
                  name={"prescribingDoctor"}
                  className="input mb-1"
                  placeholder="Juan dela Cruz"
                  component={TextInput}
                />
              </FieldContainer>
              <HospitalSelect
                name="hospital.name"
                value={values.hospital.name}
                label={t("Hospital/Clinic Name")}
                isRequired
              />
              {values.hospital.name.value === "Other" && (
                <FieldContainer
                  label="Please indicate your hospital/clinic"
                  isRequired
                >
                  <Field
                    name={"hospital.otherHospital"}
                    className="input mb-1"
                    placeholder="Philippine General Hospital"
                  />
                  <div className="has-text-danger is-size-7 mb-1">
                    <ErrorMessage name={"hospital.otherHospital"} />
                  </div>
                </FieldContainer>
              )}
              <Address
                values={values.hospital}
                isRequired
                setFieldValue={setFieldValue}
                isNationwide
                withAddressType
                fieldNames={hospitalAddressFieldValues}
                isRequired
                hasNoStreetAddress
              />
              {Object.keys(errors).length > 0 && submitCount > 0 ? (
                <Message color="danger">
                  You may have missed some required fields. Please scan through
                  the form and check if your information is complete.
                </Message>
              ) : null}
              <MediaStickyButtons isSubmitting={isSubmitting} />
            </div>
          </ContentFold>
        </Form>
      )}
    </Formik>
  )
}
// class CheckoutForm extends Component {
//   handleOnKeyPress = event => {
//     if (isNaN(event.key)) event.preventDefault()
//   }

//   render() {
//     const { t } = this.props
//     return (

//     )
//   }
// }

export default withTranslation()(CheckoutForm)
