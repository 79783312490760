import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"

const propTypes = {
  field: PropTypes.object,
  form: PropTypes.object,
  touched: PropTypes.object,
  label: PropTypes.string,
  type: PropTypes.string,
}

const TextInput = ({
  field,
  form: { touched, errors },
  label,
  type,
  list,
  dataList,
  isRequired,
  maxLength,
  isNumeric,
  onKeyPress,
  ...rest
}) => {
  let maxCharLength = maxLength
  if (!maxCharLength) maxCharLength = 250

  //* Function that prevents alpha and symbols
  //* if the fieldProps.type is number.
  //* This also prevents the user to input characters more than
  //* fieldProps.max (if defined).
  const handleOnKeyPress = event => {
    const isTypeNumber = type === "number"
    if (isNumeric && isNaN(event.key)) {
      event.preventDefault()
    } else if (isTypeNumber) {
      if (
        (event.key !== "." && isNaN(event.key)) ||
        (maxLength && event.target.value.toString().length >= maxCharLength)
      )
        event.preventDefault()
    }
    if (event.target.value.toString().length >= maxCharLength)
      event.preventDefault()
    if (onKeyPress) onKeyPress(event)
  }

  return (
    <div className="field">
      {label && (
        <label
          className={classNames("label", {
            "has-text-weight-bold": !!isRequired,
          })}
        >
          {label}
          {!!isRequired && <span className="has-text-danger"> *</span>}
        </label>
      )}
      <div className="control">
        <input
          className="input"
          type={!!type ? type : "text"}
          {...field}
          {...rest}
          list={list}
          onKeyPress={handleOnKeyPress}
        />
      </div>
      {dataList}
      {touched[field.name] && errors[field.name] && (
        <p
          className={classNames("help mb-1", {
            "is-danger": errors[field.name],
          })}
        >
          {errors[field.name]}
        </p>
      )}
    </div>
  )
}

TextInput.propTypes = propTypes

export default TextInput
