import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import CheckoutForm from '../components/forms/checkout-form/checkout-form'

const CheckoutPage = props => (
  <>
    <Layout isCentered location={props.location}>
      <SEO
        title="Checkout"
        keywords={[
          `novartis`,
          `medgrocer`,
          `pulse care`,
          `entresto`,
          `sacubitril-valsartan`,
        ]}
      />
      <CheckoutForm />
    </Layout>
  </>
)

export default CheckoutPage
